html {
  // WebKit-based browsers
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #2e2e2e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 6px;
    border: 3px solid #2e2e2e;
  }

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: #666 #2e2e2e;
}
